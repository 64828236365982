import { nanoid } from 'nanoid'

let token = ''
let initEventSent = false // Flag to control the call to send the init event
let signUpEventSent = false // Flag to control the call to send the sign up event
const uiid = nanoid()
window.uiid = uiid // Make the UUID available globally

function getMainSite () {
  return document.querySelector('meta[name="main-site"]').getAttribute('content')
}

function sendAcquisitionEvent (eventData) {
  const mainSite = getMainSite()
  const url = `${mainSite}/api/v1/acquisition_event`

  // console.log('Sending acquisition event: ', eventData, token)

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Csrf-Token': token
    },
    body: JSON.stringify(eventData)
  }).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  })
  // .then(data => console.log('Acquisition event saved:', data))
  // .catch(error => console.error('Error posting acquisition event:', error))
}

function targetOrigin () {
  return getMainSite()
}

function postDataToWindow (targetWindow, sourceParam) {
  targetWindow.postMessage({
    type: 'marketingData',
    uuid: uiid,
    source: sourceParam,
    referrer: document.referrer
  }, targetOrigin())
}

function listener (event) {
  const searchParam = new URLSearchParams(window.location.search)
  const sourceParam = searchParam.get('source')
  if (event.origin === targetOrigin()) {
    if (event.data.type === 'iFrameReady') {
      if (token === '') { // Set token if not already set
        token = event.data.token
      }
      postDataToWindow(event.source, sourceParam) // Post data to window, can be called multiple times

      // Check if it's the right time to send the init event, and if it hasn't been sent before
      if (token && !initEventSent) {
        sendAcquisitionEvent({
          dataSource: {
            uuid: uiid,
            source: sourceParam,
            referrer: document.referrer
          },
          stage: 'init'
        })
        initEventSent = true // Prevent further sending of init event
      }
    } else {
      // console.log('Received message with unexpected type (landing page): ', event.data.type)
    }
  } else {
    // console.log('Received message from unexpected origin', event);
  }
}

window.addEventListener('message', listener)

document.addEventListener('DOMContentLoaded', () => {
  const signUpLink = document.getElementById('sign-up-link')
  if (signUpLink) {
    signUpLink.addEventListener('click', () => {
      if (!signUpEventSent) {
        sendAcquisitionEvent({
          dataSource: {
            uuid: uiid,
            entryPoint: 'sign_up_link',
            referrer: document.referrer
          },
          stage: 'visit_sign_up'
        })
        signUpEventSent = true // Prevent further sending of sign up event
      }
    })
  } else {
    // console.error('Sign-up link not found in the DOM.')
  }
})
